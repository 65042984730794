body {
  background-color: #f5f6f7;
  margin: 0;
  font-family: 'Rubik', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  background: none;
}

.ant-rate-star {
  margin-right: 5px;
}

.anticon {
  vertical-align: 0;
}

.loading:before {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  z-index: 10;
  content: '';
  background-image: url('./assets/images/loading.svg');
  height: 33px;
  width: 33px;
  margin-top: -15px;
  margin-left: -15px;
}

.loading:after {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  content: ' ';
  z-index: 9;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.loading {
  position: relative;
}
